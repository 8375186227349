<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-tabs v-model="searchData.ruleType">
      <el-tab-pane v-for="item in dict_ruleType_search" :key="item.value" :label="item.name" :name="item.value"></el-tab-pane>
    </el-tabs>
    <el-button
      v-checkbtn="isShowBtn(AUTH_BTN.review_rule_add)"
      style="margin-bottom:20px"
      type="primary"
      icon="el-icon-plus"
      size="small"
      @click="$router.push({ name: 'RuleAdd' })"
      >新增
    </el-button>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getListAPI, delAPI, changeStatusAPI } from './api'
import { dict_ruleType_search } from '@/enum/dict.js'
const columns = [
  {
    label: '规则名称',
    prop: 'ruleName',
    minWidth: '300',
    customRender(h, row) {
      return <p>{row['ruleName']}</p>
    }
  },

  {
    label: '规则代码',
    prop: 'ruleCode',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['ruleCode']}</p>
    }
  },

  {
    label: '类型',
    prop: 'ruleType',
    minWidth: '100',
    customRender(h, row) {
      return (
        <div>
          <el-tag v-show={row['ruleType'] == 1} type="primary" size="small">
            前置规则
          </el-tag>
          <el-tag v-show={row['ruleType'] == 2} type="warning" size="small">
            弱规则
          </el-tag>
          <el-tag v-show={row['ruleType'] == 3} type="danger" size="small">
            强规则
          </el-tag>
        </div>
      )
    }
  },
  {
    label: '创建时间',
    prop: 'createTime',
    minWidth: '160',
    customRender(h, row) {
      return <p>{row['createTime']}</p>
    }
  },
  {
    label: '状态',
    prop: 'status',
    minWidth: '100',
    customRender(h, row) {
      return (
        <div>
          <el-switch
            disabled={!this.isShowBtn(this.AUTH_BTN.review_rule_status)}
            class="switch"
            onChange={() => this.changeStatus(row)}
            v-model={row['status']}
            active-value={true}
            inactive-value={false}
            active-text="启用"
            inactive-text="禁用"
          ></el-switch>
        </div>
      )
    }
  },

  {
    label: '操作',
    minWidth: '120',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.review_rule_detail)}
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'RuleDetail', params: { id: row['id'] } })}
          >
            详情
          </el-link>
          <el-link
            style="margin-left:10px;"
            disabled={!this.isShowBtn(this.AUTH_BTN.review_rule_edit) || row['status']}
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'RuleEdit', params: { id: row['id'] } })}
          >
            编辑
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.review_rule_del) || row['status']}
            style="margin-left:10px;"
            underline={false}
            type="primary"
            onClick={() => this.handleDel(row)}
          >
            删除
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],

  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        ruleType: ''
      },
      dict_ruleType_search
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    },
    'searchData.ruleType': {
      handler(newV, oldV) {
        this.getList()
      }
    }
  },
  mounted() {},
  activated() {
    this.getList()
  },
  methods: {
    async getList() {
      const { ruleType } = this.searchData
      let params = { page: this.currentPage, pageSize: this.pageSize }
      if (ruleType != 0) params.ruleType = ruleType
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },
    // 删除
    handleDel({ id }) {
      this.$confirm('此操作将永久删除该规则, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 启用/禁用
    changeStatus(row) {
      let { id, status } = row
      let data = { id, status: status ? true : false }
      let statusDesc = status ? '启用' : '禁用'

      this.$confirm(`此操作将${statusDesc}该规则, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          row.status = status ? true : false
          changeStatusAPI(data).then(() => {
            this.getList()
            this.$message.success('操作成功')
          })
        })
        .catch(() => {
          row.status = status ? false : true
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-divider--horizontal {
    margin: 0 0 20px;
  }

  .tp-table {
    .el-table__fixed-right {
      height: 100% !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  .el-dialog__body {
    padding: 20px 20px 50px 20px;
  }
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }

  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }

  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
